

























































@import '../../utilities/config';
.a-search-input {
  position: relative;
  &::after {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(../../assets/magnifying_glass_dark_green.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  input {
    padding-right: 42px;
  }
}
