

































































































































































@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: url(../assets/home_gray.svg);
  }
}
.page__header__tab__item {
  &--search {
    display: inline-block;
    background-image: url(../assets/magnifying_glass_dark_gray.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.3em 1.3em;
    width: 60px;
  }
}
